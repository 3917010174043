html, body, #root {
  height: 100%;
}

body.chakra-ui-light {
  background: #EDF2F7;
}

body.chakra-ui-dark {
  background: #242C3A;
}

table {
  table-layout: fixed;
}
